var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isSingleSelection)?[_c('validation-provider',{attrs:{"name":_vm.value.name,"slim":"","rules":{ required: _vm.value.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"column":"","error-messages":errors},on:{"change":function($event){return _vm.handleChange($event)}},model:{value:(_vm.value.selectedChoice),callback:function ($$v) {_vm.$set(_vm.value, "selectedChoice", $$v)},expression:"value.selectedChoice"}},_vm._l((_vm.value.choices),function(choice){return _c('v-radio',{key:choice.rank,attrs:{"value":choice,"color":"primary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(" "+_vm._s(choice.name)+" ")]),(choice.other && choice.id === _vm.$helpers.get(_vm.value.selectedChoice, 'id'))?_c('v-text-field',{staticStyle:{"width":"100%","height":"24px","max-width":"240px","margin-top":"0","padding-top":"0","margin-left":"20px"},attrs:{"hide-details":"","placeholder":_vm.$t('hr.survey.enter_answer')},model:{value:(_vm.value.answer),callback:function ($$v) {_vm.$set(_vm.value, "answer", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.answer"}}):_vm._e()],1)]},proxy:true}],null,true)})}),1)]}}],null,false,2090009766)})]:_vm._e(),(_vm.isMultiSelection)?[_c('validation-provider',{attrs:{"name":_vm.value.name,"slim":"","rules":{ required: _vm.value.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-input',{attrs:{"error-messages":errors},model:{value:(_vm.value.selectedChoices),callback:function ($$v) {_vm.$set(_vm.value, "selectedChoices", $$v)},expression:"value.selectedChoices"}},[_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.value.choices),function(choice){return _c('v-checkbox',{key:choice.rank,staticClass:"align-center",attrs:{"hide-details":"","multiple":"","label":choice.name,"value":choice,"color":"primary","disabled":_vm.isOtherSelected(choice),"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(" "+_vm._s(choice.name)+" ")]),(choice.other && _vm.value.selectedChoices.includes(choice))?_c('v-text-field',{staticStyle:{"width":"100%","height":"24px","max-width":"240px","margin-top":"0","padding-top":"0","margin-left":"20px"},attrs:{"hide-details":"","placeholder":_vm.$t('hr.survey.enter_answer')},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.value.answer),callback:function ($$v) {_vm.$set(_vm.value, "answer", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.answer"}}):_vm._e()],1)]},proxy:true}],null,true),model:{value:(_vm.value.selectedChoices),callback:function ($$v) {_vm.$set(_vm.value, "selectedChoices", $$v)},expression:"value.selectedChoices"}})}),1)])]}}],null,false,1919658170)})]:_vm._e(),(_vm.isText)?[_c('validation-provider',{attrs:{"name":_vm.value.name,"slim":"","rules":{ required: _vm.value.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","rows":"3","no-resize":"","single-line":"","placeholder":_vm.$t('hr.survey.your_answer'),"error-messages":errors},model:{value:(_vm.value.answer),callback:function ($$v) {_vm.$set(_vm.value, "answer", $$v)},expression:"value.answer"}})]}}],null,false,3676315778)})]:_vm._e(),(_vm.isCombobox)?[_c('validation-provider',{attrs:{"name":_vm.value.name,"slim":"","rules":{ required: _vm.value.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.value.choices,"item-text":"name","item-value":"id","return-object":"","clearable":"","label":_vm.$t('hr.survey.please_select'),"error-messages":errors,"menu-props":{ offsetY: true, overflowY: true, closeOnContentClick: true }},on:{"input":function($event){return _vm.handleChange($event)}},model:{value:(_vm.value.selectedChoice),callback:function ($$v) {_vm.$set(_vm.value, "selectedChoice", $$v)},expression:"value.selectedChoice"}})]}}],null,false,1010451226)})]:_vm._e(),(_vm.isDate)?[_c('validation-provider',{attrs:{"name":_vm.value.name,"slim":"","rules":{ required: _vm.value.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"label":_vm.value.name,"prepend-inner-icon":"mdi-calendar","error-messages":errors},model:{value:(_vm.value.answer),callback:function ($$v) {_vm.$set(_vm.value, "answer", $$v)},expression:"value.answer"}})]}}],null,false,4207978112)})]:_vm._e(),(_vm.isTime)?[_c('validation-provider',{attrs:{"name":_vm.value.name,"slim":"","rules":{ required: _vm.value.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('time-picker',{attrs:{"label":_vm.value.name,"prepend-inner-icon":"mdi-clock","error-messages":errors},model:{value:(_vm.value.answer),callback:function ($$v) {_vm.$set(_vm.value, "answer", $$v)},expression:"value.answer"}})]}}],null,false,1811826107)})]:_vm._e(),(_vm.isLinearScale)?[_c('validation-provider',{attrs:{"name":_vm.value.name,"slim":"","rules":{ required: _vm.value.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors},on:{"change":function($event){return _vm.handleChange($event)}},model:{value:(_vm.value.selectedChoice),callback:function ($$v) {_vm.$set(_vm.value, "selectedChoice", $$v)},expression:"value.selectedChoice"}},_vm._l((_vm.value.choices),function(choice){return _c('v-radio',{key:choice.rank,staticClass:"mr-2 mb-2",attrs:{"value":choice,"label":choice.name,"color":"primary"}})}),1)]}}],null,false,3293544967)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }