<template>
  <div>
    <template v-if="isSingleSelection">
      <validation-provider :name="value.name" slim :rules="{ required: value.required }" v-slot="{ errors }">
        <v-radio-group v-model="value.selectedChoice" column :error-messages="errors" @change="handleChange($event)">
          <v-radio v-for="choice in value.choices" :key="choice.rank" :value="choice" color="primary">
            <template v-slot:label>
              <div class="d-flex align-center">
                <span>
                  {{ choice.name }}
                </span>
                <v-text-field
                  v-if="choice.other && choice.id === $helpers.get(value.selectedChoice, 'id')"
                  v-model.trim="value.answer"
                  style="width: 100%; height: 24px; max-width: 240px; margin-top: 0; padding-top: 0; margin-left: 20px"
                  hide-details
                  :placeholder="$t('hr.survey.enter_answer')"
                />
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </validation-provider>
    </template>
    <template v-if="isMultiSelection">
      <validation-provider :name="value.name" slim :rules="{ required: value.required }" v-slot="{ errors }">
        <v-input v-model="value.selectedChoices" :error-messages="errors">
          <div class="d-flex flex-column">
            <v-checkbox
              v-model="value.selectedChoices"
              v-for="choice in value.choices"
              hide-details
              multiple
              :label="choice.name"
              :key="choice.rank"
              :value="choice"
              color="primary"
              class="align-center"
              :disabled="isOtherSelected(choice)"
              :error-messages="errors"
            >
              <template v-slot:label>
                <div class="d-flex align-center">
                  <span>
                    {{ choice.name }}
                  </span>
                  <v-text-field
                    v-if="choice.other && value.selectedChoices.includes(choice)"
                    v-model.trim="value.answer"
                    style="width: 100%; height: 24px; max-width: 240px; margin-top: 0; padding-top: 0; margin-left: 20px"
                    hide-details
                    :placeholder="$t('hr.survey.enter_answer')"
                    @click.stop=""
                  />
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-input>
      </validation-provider>
    </template>
    <template v-if="isText">
      <validation-provider :name="value.name" slim :rules="{ required: value.required }" v-slot="{ errors }">
        <v-textarea
          v-model="value.answer"
          outlined
          class="mt-5"
          rows="3"
          no-resize
          single-line
          :placeholder="$t('hr.survey.your_answer')"
          :error-messages="errors"
        />
      </validation-provider>
    </template>
    <template v-if="isCombobox">
      <validation-provider :name="value.name" slim :rules="{ required: value.required }" v-slot="{ errors }">
        <v-select
          v-model="value.selectedChoice"
          :items="value.choices"
          item-text="name"
          item-value="id"
          return-object
          clearable
          :label="$t('hr.survey.please_select')"
          :error-messages="errors"
          :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
          @input="handleChange($event)"
        />
      </validation-provider>
    </template>
    <template v-if="isDate">
      <validation-provider :name="value.name" slim :rules="{ required: value.required }" v-slot="{ errors }">
        <date-picker
          v-model="value.answer"
          :label="value.name"
          prepend-inner-icon="mdi-calendar"
          :error-messages="errors"
        />
      </validation-provider>
    </template>
    <template v-if="isTime">
      <validation-provider :name="value.name" slim :rules="{ required: value.required }" v-slot="{ errors }">
        <time-picker
          v-model="value.answer"
          :label="value.name"
          prepend-inner-icon="mdi-clock"
          :error-messages="errors"
        />
      </validation-provider>
    </template>
    <template v-if="isLinearScale">
      <validation-provider :name="value.name" slim :rules="{ required: value.required }" v-slot="{ errors }">
        <v-radio-group v-model="value.selectedChoice" @change="handleChange($event)" row :error-messages="errors">
          <v-radio
            class="mr-2 mb-2"
            v-for="choice in value.choices"
            :key="choice.rank"
            :value="choice"
            :label="choice.name"
            color="primary"
          />
        </v-radio-group>
      </validation-provider>
    </template>
  </div>
</template>

<script>
  import { AnswerType } from "../enums";
  export default {
    name: "SurveyQuestionAnswer",
    props: {
      value: Object
    },
    data: vm => ({}),
    computed: {
      isSingleSelection() {
        return this.value.answerType === AnswerType.SINGLE_SELECTION;
      },
      isMultiSelection() {
        return this.value.answerType === AnswerType.MULTI_SELECTION;
      },
      isText() {
        return this.value.answerType === AnswerType.TEXT;
      },
      isCombobox() {
        return this.value.answerType === AnswerType.COMBOBOX;
      },
      isDate() {
        return this.value.answerType === AnswerType.DATE;
      },
      isTime() {
        return this.value.answerType === AnswerType.TIME;
      },
      isLinearScale() {
        return this.value.answerType === AnswerType.LINEAR_SCALE;
      }
    },
    methods: {
      isOtherSelected(choice) {
        return (
          (this.value.selectedChoices.some(item => item.other) && !this.value.selectedChoices.includes(choice)) ||
          (this.value.selectedChoices.some(item => !item.other) && choice.other)
        );
      },
      handleChange(value) {
        this.value.selectedChoices = value ? [value] : [];
      }
    }
  };
</script>

<style></style>
